import React from "react";

function QuestSvg({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 2.66797H22.6667C23.5507 2.66797 24.3986 3.01916 25.0237 3.64428C25.6488 4.2694 26 5.11725 26 6.0013V15.0853C24.4807 14.593 22.8548 14.5302 21.302 14.9037C19.7492 15.2771 18.3298 16.0724 17.2005 17.2018C16.0711 18.3311 15.2758 19.7505 14.9024 21.3033C14.5289 22.8561 14.5917 24.482 15.084 26.0013H6C6 26.3549 6.14048 26.6941 6.39052 26.9441C6.64057 27.1942 6.97971 27.3346 7.33333 27.3346H15.6427C16.0244 28.0662 16.5084 28.7396 17.08 29.3346H7.33333C6.44928 29.3346 5.60143 28.9834 4.97631 28.3583C4.35119 27.7332 4 26.8854 4 26.0013V6.0013C4 5.11725 4.35119 4.2694 4.97631 3.64428C5.60143 3.01916 6.44928 2.66797 7.33333 2.66797ZM9.33333 6.66797C8.97971 6.66797 8.64057 6.80844 8.39052 7.05849C8.14048 7.30854 8 7.64768 8 8.0013V9.33464C8 9.68826 8.14048 10.0274 8.39052 10.2774C8.64057 10.5275 8.97971 10.668 9.33333 10.668H20C20.3536 10.668 20.6928 10.5275 20.9428 10.2774C21.1929 10.0274 21.3333 9.68826 21.3333 9.33464V8.0013C21.3333 7.64768 21.1929 7.30854 20.9428 7.05849C20.6928 6.80844 20.3536 6.66797 20 6.66797H9.33333Z"
        fill="white"
      />
      <path
        d="M30.6667 23.3333C30.6667 21.3884 29.8941 19.5232 28.5188 18.1479C27.1435 16.7726 25.2783 16 23.3333 16C21.3884 16 19.5232 16.7726 18.1479 18.1479C16.7726 19.5232 16 21.3884 16 23.3333C16 25.2783 16.7726 27.1435 18.1479 28.5188C19.5232 29.8941 21.3884 30.6667 23.3333 30.6667C25.2783 30.6667 27.1435 29.8941 28.5188 28.5188C29.8941 27.1435 30.6667 25.2783 30.6667 23.3333ZM22.552 20.1293L26.2933 22.5827C26.4093 22.6571 26.5044 22.7598 26.5697 22.8811C26.635 23.0023 26.6684 23.1383 26.6667 23.276C26.6668 23.4146 26.6339 23.5513 26.5707 23.6747C26.511 23.7968 26.4199 23.9008 26.3067 23.976L22.564 26.5293C22.4989 26.5732 22.428 26.6077 22.3533 26.632C22.18 26.6873 21.9924 26.6764 21.8267 26.6013C21.7312 26.5566 21.6441 26.4957 21.5693 26.4213C21.4971 26.3427 21.4386 26.2526 21.396 26.1547C21.3544 26.0545 21.3331 25.9471 21.3333 25.8387V20.828C21.3326 20.6676 21.3785 20.5104 21.4654 20.3755C21.5522 20.2406 21.6763 20.1338 21.8227 20.068C21.9389 20.0135 22.0674 19.9904 22.1953 20.0009C22.3233 20.0115 22.4462 20.0553 22.552 20.128V20.1293Z"
        fill="white"
      />
    </svg>
  );
}

export default QuestSvg;
