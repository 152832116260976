import React from "react";

export default function PlayCircleSvg({ className, onClick, clr }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5 59C13.2092 59 2.30956e-06 45.7908 5.15794e-06 29.5C8.00633e-06 13.2091 13.2092 -9.13453e-06 29.5 -6.28615e-06C45.7908 -3.43777e-06 59 13.2092 59 29.5C59 45.7909 45.7908 59 29.5 59ZM38.9887 29.0456L24.6075 18.5824C24.5286 18.5257 24.4357 18.4919 24.3389 18.4846C24.2421 18.4773 24.1451 18.4969 24.0587 18.5411C23.9723 18.5854 23.8997 18.6526 23.849 18.7353C23.7983 18.8181 23.7714 18.9133 23.7712 19.0104L23.7712 39.9238C23.7709 40.0211 23.7975 40.1165 23.8481 40.1996C23.8987 40.2827 23.9714 40.3501 24.058 40.3944C24.1446 40.4388 24.2418 40.4582 24.3388 40.4507C24.4357 40.4432 24.5288 40.4089 24.6075 40.3518L38.9887 29.8951C39.0567 29.847 39.1121 29.7834 39.1503 29.7095C39.1886 29.6356 39.2086 29.5536 39.2086 29.4704C39.2086 29.3872 39.1886 29.3052 39.1503 29.2312C39.1121 29.1573 39.0567 29.0937 38.9887 29.0456Z"
        fill="white"
      />
    </svg>
  );
}
